import { Button, Card, CardContent, Collapse, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector, shallowEqual } from "react-redux";
import Loader from "../../Loader";
import peopleAPI from "../../_api/_people";
import UsersAPI from "../../_api/_usersAPI";
import DeleteAccountModal from "./DeleteAccountModal";
import EditPasswordForm from "./EditPasswordForm";
import { logout } from "../../redux/actions/authActions";
import { toast } from "react-toastify";
// import PEConnectButton from "../common/PEConnectButton";

const AccountParams = () => {
  const { t } = useTranslation("common");

  // const url2 = useSelector((state: any) => state.home?.check_saml?.url2);
  const tokenData = useSelector((state: any) => state.user, shallowEqual);

  const [badOldPwd, setBadOldPWd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const roles = useSelector((state: any) => state.user.roles);

  const admin = ["ROLE_ADMIN", "ROLE_ADMINADVISOR", "ROLE_ADMINRECRUITER"];

  const canDeleteAccount = admin.some((adminRole) => roles.includes(adminRole));

  const dispatch = useDispatch();
  const handleLogout = async () => {
    try {
      await axios.post("logout", null);
      dispatch(logout());
    } catch (err) {
      console.log("Can't logout");
      // Can't logout
    }
  };

  const handleSubmit = async (values: any) => {
    const log = { username: tokenData.email, password: values.oldPwd };
    try {
      setLoading(true);
      axios.defaults.headers["X-Context"] = null;
      const response = await axios.post("login_check", log);
      response && updatePwd(values);
    } catch (err: any) {
      setLoading(false);
      err.data.message === "Invalid credentials." && setBadOldPWd(true);
      toast.error(t("errors.error_append"));
    }
  };

  useEffect(() => {
    let to: any;
    if (success) {
      to = setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
    return () => {
      to && clearTimeout(to);
    };
  }, [success]);

  const updatePwd = async (values: any) => {
    try {
      const res: any = await UsersAPI.put(tokenData.user_id, {
        password: values.newPwd,
      });
      if (res) {
        setBadOldPWd(false);
        setSuccess(true);
        setLoading(false);
      }
      toast.success(t("success.pwd_modified"));
    } catch (err) {
      setLoading(false);
      toast.error(t("errors.error_append"));
    }
  };

  const handleDelete = async () => {
    setOpen(false);
    try {
      const res = await peopleAPI.delete(tokenData.person_id);
      res && handleLogout();
    } catch (err) {}
  };

  return (
    <>
      <Typography variant="h4">{t("account.params.pwd")}</Typography>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          {badOldPwd && (
            <Typography
              sx={{ mb: 1 }}
              textAlign="center"
              variant="body1"
              color="error"
            >
              {t("account.params.incorrect_actual_pwd")}
            </Typography>
          )}
          {!success ? (
            <Collapse in={!success}>
              <EditPasswordForm loading={loading} submit={handleSubmit} />
            </Collapse>
          ) : (
            <>
              <Typography
                sx={{ mb: 1 }}
                textAlign="center"
                variant="h5"
                color="primary"
              >
                {t("account.params.pwd_updated")}
              </Typography>
              <Loader />
            </>
          )}
        </CardContent>
      </Card>
      {/* 
      {url2 && (
        <Box display="flex" justifyContent="center" marginTop={2}>
          <Box display="flex" justifyContent="center" width="250px">
            <PEConnectButton link />
          </Box>
        </Box>
      )} */}

      {(canDeleteAccount ||
        (!roles.includes("ROLE_ADVISOR") &&
          !roles.includes("ROLE_CRE") &&
          !roles.includes("ROLE_RECRUITER"))) && (
        <Box textAlign="center" sx={{ mt: 3 }}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setOpen(!open)}
            sx={{ height: "55px", width: "250px", fontSize: "12px" }}
          >
            {t("account.params.delete_account")}
          </Button>
        </Box>
      )}

      <DeleteAccountModal
        open={open}
        close={() => setOpen(false)}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default AccountParams;
